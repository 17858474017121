import { mapActions, mapState } from 'vuex'

export default{
    
  data: () => ({
    collections: [],
    params: {},
    message: '',
    error: false,
    errors: {},
    loading: false,
    meta: {},
    itemDelete: {},
    itemSelected: {},
    loadingReprocess: false,
    onlyMyImports: false,
    onlySystemImports: false,
    model: {},
    show: false,
    text: 'Carregando dados...',
    loadingProgress: false,
    loadingRefresh: false,
    textDelete: '',
    deleting: false,
    hide_imports: false,
    headers: [
        { text: 'Item', value: 'import'},
        { text: 'Data', value: 'updated_at', align: 'center'},
        { text: 'Usuário', value: 'user', align: 'center'},
        { text: 'Status', value: 'status', with: '20px', align: 'center'},
        { text: '', value: 'actions'},
    ]
    }),

    computed: {
        ...mapState('auth', ['user', 'acesso', 'token']),

        hideImports(){
            
            if(this.user && this.user.roles && this.acesso){
                
                let find = this.user.roles.filter(r => (r.institution_id == this.acesso.instituicao.id && r.hide_imports == 1));

                if(find && find.length){
                    return true;
                }else{
                    return false;
                }

            }else{
                return false;
            }
            
        },
    },
    
    watch: {
      params(params_new, params_old){
        if(params_new != params_old) {
            this.getData();
        }
      },
       //Função para tratar quando um item for exibido para deletar
      itemDelete(item_new, item_old){
          if(item_new !== item_old && Object.keys(item_new).length > 0)
          {
              this.show = true;
              this.textDelete = "Deseja excluir o item?";
          } else {
              this.show = false
              this.textDelete = '';
          }
      }, //Função para limpar a propriedade do usuário que será excluído quando usuário clica no cancelar
      show(show_new, show_old) {
          
          if(show_new != show_old && !show_new) {
              this.itemDelete = {};
          }
      },

      onlyMyImports(val){
        this.getData();
      },

      onlySystemImports(val){
        this.getData();
      },

      model(item_new, item_old){
          
        if(item_new !== item_old && this.model.id)
          {
              this.errors = {};
          } else if(Object.keys(this.model).length === 0){
              this.errors = {};
          }           
      },

      
    },
    methods: {
      ...mapActions('user', ['ActionIndexUserImports', 'ActionDestroyUserImport', 'ActionUserImportItemReprocess']),
        
      getData(refresh = false){
        
        this.error = false;
        this.message = '';
        this.loadingProgress = true;
        this.loadingRefresh = refresh;
        
        let payload = this.params;

        payload.with = 'status,user,import';
        payload.column_order = 'updated_at';
        payload.direction = 'desc';

        payload.find_columns = {};

        if(this.onlyMyImports){
            payload.find_columns.user_id = this.user.id;
        }else{
            delete payload.find_columns.user_id;
        }

        if(this.onlySystemImports){
            payload.find_columns.by_integration = 1;
        }else{
            payload.find_columns.by_integration = 0;
        }

        this.ActionIndexUserImports(payload)
            .then((res) => {
                this.collections = res.data;
                this.meta = res.meta;
            })
            .finally(() => {
                this.loading = false;
                this.loadingProgress = false;
                this.loadingRefresh = false;
            })
    },

    reprocess(item){

        this.loadingReprocess = true;
        this.itemSelected = item;

        this.ActionUserImportItemReprocess({id: item.id})
            .then((res) => {
                this.getData();
            })
            .catch((error) =>{
               
            })
            .finally(() => {
                this.loadingReprocess = false;
            });

    },

    deleteItem()
    {
        this.deleting = true;
        this.message = '';

        this.ActionDestroyUserImport({id: this.itemDelete.id})
            .then((res) => {
                this.message = res.message;
                this.show = false;
                this.getData();
            })
            .catch((error) =>{
                this.message = error.message;
                this.error = true;
            })
            .finally(() => {
                this.deleting = false;
            });
    },

    onCompleted(error, message){
        if(error){
            this.message = '';
            this.$nextTick(() => {
                this.message = message;
                this.error = error;
            });
        }else{
            this.isCompleted = true;
            this.getData();
        }
        
        
    },

  },

}
