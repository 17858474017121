var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main-container",
    {
      attrs: { icon: _vm.$route.meta.icon, title: _vm.$route.meta.title },
      scopedSlots: _vm._u([
        {
          key: "tools",
          fn: function() {
            return [
              _vm.$vuetify.breakpoint.name == "xs" ||
              _vm.$vuetify.breakpoint.name == "sm"
                ? _c(
                    "span",
                    [
                      _c("emc-info-help", {
                        attrs: {
                          title: "Informações",
                          text:
                            "Os relatórios ficarão disponíveis para download por 90 dias!"
                        }
                      })
                    ],
                    1
                  )
                : _c(
                    "span",
                    [
                      _c("v-icon", { attrs: { color: "primary", small: "" } }, [
                        _vm._v("mdi-information-outline")
                      ]),
                      _c("strong", { staticClass: "subtitle-1 ml-1  " }, [
                        _vm._v(
                          "Os relatórios ficarão disponíveis para download por 90 dias!"
                        )
                      ])
                    ],
                    1
                  ),
              _c("v-spacer"),
              _c("sys-pop-over-report", {
                attrs: {
                  reports: _vm.reportsGrids,
                  openOnHover: "",
                  title: "Grades",
                  icon: "mdi-grid-large"
                },
                on: { onCompleted: _vm.onCompleted }
              }),
              _c("sys-pop-over-report", {
                attrs: {
                  reports: _vm.reportsTimeTables,
                  openOnHover: "",
                  title: "Horários",
                  icon: "mdi-calendar-clock"
                },
                on: { onCompleted: _vm.onCompleted }
              }),
              _c("sys-pop-over-report", {
                attrs: {
                  reports: [72, 56, 61, 99],
                  openOnHover: "",
                  title: "Ambientes",
                  icon: "mdi-home-import-outline"
                },
                on: { onCompleted: _vm.onCompleted }
              }),
              _c("sys-pop-over-report", {
                attrs: {
                  reports: [31, 2, 10, 1, 55, 110],
                  openOnHover: "",
                  title: "Professores",
                  icon: "school"
                },
                on: { onCompleted: _vm.onCompleted }
              }),
              _c("sys-pop-over-report", {
                attrs: {
                  reports: _vm.reportsOthers,
                  openOnHover: "",
                  title: "Demais Relatórios",
                  icon: "mdi-format-list-bulleted"
                },
                on: { onCompleted: _vm.onCompleted }
              }),
              _vm.hasIntegration(1)
                ? _c("v-divider", {
                    attrs: { vertical: "", inset: "", dense: "" }
                  })
                : _vm._e(),
              _vm.hasIntegration(1)
                ? _c("sys-pop-over-report", {
                    attrs: { "report-type": 2, title: "Totvs" },
                    on: { onCompleted: _vm.onCompleted }
                  })
                : _vm._e()
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c("emc-loader-progress", { attrs: { show: _vm.loadingProgress } }),
      _c("emc-table-items", {
        attrs: {
          headers: _vm.headers,
          params: _vm.params,
          collections: _vm.collections,
          meta: _vm.meta,
          itemDelete: _vm.itemDelete,
          itemEdit: _vm.model,
          loading: _vm.loading,
          "hide-edit": "",
          showDelete: false,
          label: "Pesquisar por nome do relatório ou usuário que gerou",
          showAppend: ""
        },
        on: {
          "update:params": function($event) {
            _vm.params = $event
          },
          "update:collections": function($event) {
            _vm.collections = $event
          },
          "update:meta": function($event) {
            _vm.meta = $event
          },
          "update:itemDelete": function($event) {
            _vm.itemDelete = $event
          },
          "update:item-delete": function($event) {
            _vm.itemDelete = $event
          },
          "update:itemEdit": function($event) {
            _vm.model = $event
          },
          "update:item-edit": function($event) {
            _vm.model = $event
          }
        },
        scopedSlots: _vm._u([
          {
            key: "append",
            fn: function() {
              return [
                _c(
                  "v-tooltip",
                  {
                    attrs: { bottom: "" },
                    scopedSlots: _vm._u([
                      {
                        key: "activator",
                        fn: function(ref) {
                          var on = ref.on
                          var attrs = ref.attrs
                          return [
                            _c(
                              "v-btn",
                              _vm._g(
                                _vm._b(
                                  {
                                    attrs: {
                                      loading: _vm.loadingRefresh,
                                      color: "success",
                                      icon: ""
                                    },
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        $event.stopPropagation()
                                        return _vm.getData(true)
                                      }
                                    }
                                  },
                                  "v-btn",
                                  attrs,
                                  false
                                ),
                                on
                              ),
                              [
                                _c("v-icon", { attrs: { btn: "" } }, [
                                  _vm._v("mdi-refresh-circle")
                                ])
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ])
                  },
                  [_c("span", [_vm._v(" Atualizar ")])]
                )
              ]
            },
            proxy: true
          },
          {
            key: "item.report",
            fn: function(ref) {
              var item = ref.item
              return [
                _vm._v(" " + _vm._s(item.report.name) + " "),
                item.report.private
                  ? _c("emc-info-help", {
                      attrs: {
                        title: "Informações",
                        iconColor: "orange",
                        icon: "mdi-lock-open-alert",
                        text:
                          "Relatório Privado. Acesso permitido apenas ao usuário que gerou o relatório ou o Administrador do Sistema"
                      }
                    })
                  : _vm._e(),
                item.description
                  ? _c(
                      "v-tooltip",
                      {
                        attrs: { bottom: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                var attrs = ref.attrs
                                return [
                                  _c(
                                    "small",
                                    _vm._g(
                                      _vm._b({}, "small", attrs, false),
                                      on
                                    ),
                                    [
                                      _c("br"),
                                      _vm._v(
                                        _vm._s(
                                          item.description.length > 80
                                            ? item.description.substring(
                                                0,
                                                50
                                              ) + "..."
                                            : item.description
                                        )
                                      )
                                    ]
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      },
                      [
                        _c("span", [
                          _vm._v(" " + _vm._s(item.description) + " ")
                        ])
                      ]
                    )
                  : _vm._e()
              ]
            }
          },
          {
            key: "item.updated_at",
            fn: function(ref) {
              var item = ref.item
              return [
                _vm._v(" " + _vm._s(item.updated_at_human) + " "),
                _c("br"),
                _c("small", [
                  _vm._v(_vm._s(_vm._f("dateTime")(item.updated_at)))
                ])
              ]
            }
          },
          {
            key: "item.status",
            fn: function(ref) {
              var item = ref.item
              return [
                _vm._v(" " + _vm._s(item.status.name) + " "),
                item.status.id == 2
                  ? _c(
                      "span",
                      [
                        _c("v-progress-linear", {
                          attrs: {
                            color: "deep-purple accent-4",
                            indeterminate: "",
                            rounded: "",
                            height: "3"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e()
              ]
            }
          },
          {
            key: "actions",
            fn: function(ref) {
              var item = ref.item
              return [
                _c(
                  "v-tooltip",
                  {
                    attrs: { bottom: "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            var attrs = ref.attrs
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  _vm._b(
                                    {
                                      attrs: {
                                        disabled:
                                          item.user_download_status_id != 3,
                                        small: "",
                                        icon: "",
                                        href:
                                          item.url_file_name +
                                          "?token=" +
                                          _vm.token,
                                        target: "_blank"
                                      }
                                    },
                                    "v-btn",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { small: "", color: "success" } },
                                    [_vm._v("mdi-download")]
                                  )
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    )
                  },
                  [_c("span", [_vm._v(" Dowload ")])]
                ),
                _c("emc-table-icon-delete", {
                  attrs: { disabled: !_vm.user.is_internal },
                  on: {
                    click: function($event) {
                      _vm.itemDelete = item
                    }
                  }
                })
              ]
            }
          },
          {
            key: "footer.prepend",
            fn: function() {
              return [
                _c("v-switch", {
                  staticClass: "ml-3",
                  attrs: { inset: "", label: "Exibir apenas meus relatórios" },
                  model: {
                    value: _vm.onlyMyReports,
                    callback: function($$v) {
                      _vm.onlyMyReports = $$v
                    },
                    expression: "onlyMyReports"
                  }
                })
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("emc-alert-modal", {
        attrs: { text: _vm.textDelete, disabled: _vm.deleting, show: _vm.show },
        on: {
          "update:show": function($event) {
            _vm.show = $event
          }
        },
        scopedSlots: _vm._u([
          {
            key: "actions",
            fn: function() {
              return [
                _c(
                  "v-btn",
                  {
                    attrs: {
                      color: "error",
                      loading: _vm.deleting,
                      disabled: _vm.deleting
                    },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        return _vm.deleteItem()
                      }
                    }
                  },
                  [
                    _vm._v("Excluir "),
                    _c("v-icon", { attrs: { right: "", dark: "" } }, [
                      _vm._v("mdi-delete")
                    ])
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("emc-alert-snackbar", {
        attrs: {
          show: !!_vm.message.length,
          message: _vm.message,
          color: _vm.error ? "error" : "success"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }